/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../../framework/API/api_digitalSign";
import { toast } from 'react-toastify';
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";
import { LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { isArray } from "underscore";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const DigitalSignScreenGroupCollectionSelection = memo((props) => {

    const lang = useTranslation();

    let wherestatement = props?.wherestatement;
    let andWhereStatement = props?.andWhereStatement;
    let Company_id = props?.Company_id ? isArray(props?.Company_id) ? props?.Company_id : [props?.Company_id] :  utility.getValue(LOCALSTORAGE_KEY.COMPANIES).map(x=>x?._id);

    const [gridData, setGridData] = useState([]);
    const [originalGridData, setoriginalGridData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedState, setSelectedState] = useState(
        props.seletedState ? utility.getSelectedStateCollectionObject(props.seletedState, DATA_ITEM_KEY) : {}
    );
    const [count, setCount] = useState(0);
    const gridRef = useRef();
    const per_page = props.perPage ?? 50;
    let page_take = props.page_take ?? 50;
    const [currentPage, setCurrentPage] = useState(1);

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    useEffect(() => {
        setIsLoading(true)
        setPage({ skip: 0, take: page_take });
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText, Company_id);
    }, []);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        if (searchText == null) return;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                loaddata({ per_page: page.take, current_page: 1 }, wherestatement, andWhereStatement, searchText, Company_id);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, Company_id);
        }
    }, [searchText]);

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, Company_id) => {
        setIsLoading(true);
        try {
            let json = await API.getScreenGroup({ perPage: pagination?.per_page, page: pagination?.page, query: wherestatement }, searchText, Company_id);
            setoriginalGridData(json.data);
            setGridData(json.data);
            setCount(json.pagination.total);

        } catch (error) {
            toast.error(lang.error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setIsLoading(false);

    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);

        }, [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {

        if (props.mode == 'single') {
            toast.info(`${lang.select_any_one_collection_selection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

    }, []);

    const checkIfItemExist = (item) => {

        let isFound = false;
        let existingEntries = props.existingItems ?? [];
        if (props.checkDuplicate ?? false) {
            isFound = existingEntries.some(element => {
                console.log("props");
                console.log(props);
                if (element._id === item._id) {
                    return true;
                }

                return false;
            });
        }
        // console.log("isFound");
        // console.log(isFound);
        return isFound;

    };

    //to sendDataList to RailsEditForm and to close the collection
    const onSelection = () => {
        let msg = `${lang.please_select_first_collection_error_message}`

        let selectedDataList = [];
        //loop for setting the selected obj in array
        gridData.map((obj) => {
            let key = obj._id;
            //if true means checked
            if (selectedState[key]) {
                // checking duplicate as well
                if (!checkIfItemExist(obj)) {
                    selectedDataList.push(obj);
                } else {
                    msg = `${lang.already_attached_collection_error_message}`
                }
            }
        })

        if (selectedDataList.length != 0) {
            props.setDataList(selectedDataList);
            props.closeForm();
        } else {
            // alert(msg)
            toast.info(msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    //load for infinit scroll
    const loadInfiniteData = async () => {
        setCurrentPage(currentPage + 1);
        if (count > originalGridData.length) {
            let json = await API.getScreenGroup({ perPage: per_page, page: currentPage + 1, query: wherestatement }, searchText);
            setoriginalGridData((old) => [...old, ...json.data]);
            setGridData((old) => [...old, ...json.data]);
            setSelectedState({});
        }

    }

    const refreshCollection = () => {
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText, Company_id);
    }

    //for infinite scroll
    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.className.indexOf('k-grid-content') === -1) {
            return;
        }

        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            await loadInfiniteData()
        }
    };

    const onRowDoubleClick = (event) => {
        const item = event.dataItem;

        if (!checkIfItemExist(item)) {
            props.setDataList([item], selectedState);
            props.closeForm();
        } else {
            toast.info(`${lang.duplicate_item_selected_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    return (
        <BossDialog
            title={props.title ?? `${lang.add_dialog_header}`}
            onClose={props.closeForm}
            width={props.width ?? "75vw"}
            height={props.height ?? "auto"}
        >
            <div className="flex-container mb-3">
                <div style={{ marginLeft: '-10px' }}>
                    <SaveButton title={props.addButtonTitle ?? `${lang.add_button_text}`} style={{ backgroundColor: 'green', borderRadius: "4px" }} onClick={onSelection}></SaveButton>
                    <CancelButton onClick={props.closeForm}></CancelButton>
                </div>
                <div className="flex-container-reverse flex-item-auto">
                    <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>
                    <input
                        type="text"
                        className="form-control shadow-inset-2 pt-0 pb-0"
                        id="searchInput"
                        placeholder={lang.search_button_text}
                        onChange={onSearch}
                        style={{ width: '200px' }}
                        onFocus={(e) => {
                            if (props.setOutSideFocus) {
                                props.setOutSideFocus(true);
                            }
                        }}
                        onBlur={(e) => {
                            if (props.setOutSideFocus) {
                                props.setOutSideFocus(false);
                            }
                        }}
                    />
                    <RefreshButton onClick={refreshCollection} style={{ marginRight: "5px", marginTop: "3px" }} />
                </div>
            </div>

            <div>
                {isLoading && <Loader height={props?.Height ?? "42vh"} />}
                {!isLoading &&
                    <Grid
                        data={gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}

                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: props.mode ?? "multiple",
                        }}
                        ref={gridRef}
                        resizable={true}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        onRowDoubleClick={onRowDoubleClick}
                        style={{ height: props?.height ?? "42vh" }}
                        onScroll={scrollHandler}
                    >
                        <GridColumn
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={
                                gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
                            }
                            locked={true}
                        />
                        <GridColumn field="Name" title={lang.name_column} />
                        <GridColumn field="Description" title={lang.description_column} />
                        <GridColumn field="ScreenCount" title={lang.attach_screens_column} />
                        <GridColumn field="Archive" title={lang.archive} cell={CheckboxCell}/>
                    </Grid>}
            </div>
            <div className="text-right mt-1">{`${lang.total_label} : ${count}`}</div>
        </BossDialog>
    );
});

export default DigitalSignScreenGroupCollectionSelection;
