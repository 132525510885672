import { getData } from "../../framework/API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES, MEDIACATEGORYTYPES, SCHEDULETYPE } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import * as API from '../../framework/API/api'
import _ from "underscore";
import moment from "moment";

const getCheckListGridItemStructure = (data, message) => {

    var gridData = []
    if (data.length > 0) {
        gridData = data.map((obj) => {
            return {
                _id : obj._id,
                Title: obj.mediaEpisode.Title,
                SlotDateTime: utility.convertMilisecondsToDateTimeString(obj.SlotDateTime),
                AssetId: obj.mediaEpisode.AssetId,
                Type: Object.keys(SCHEDULETYPE).find(key => SCHEDULETYPE[key] === obj.Type) ?? obj.mediaEpisode.MediaCategory.Description,
                Message: message,
            }
        })
    }
    return gridData;

}

//to check if same commercial are scheduled back to back
export const getBackToBackMediaInSchedule = async (data) => {
    var allChildrens = [];
    var backToBackMediaList = [];

    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children];
        })
    }

    //allChildrens orderBy SlotDateTime
    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

    for (let i = 0; i < allChildrens.length - 1; i++) {
        if (allChildrens[i].Type > SCHEDULETYPE.Segment && allChildrens[i].MediaEpisode_id == allChildrens[i + 1].MediaEpisode_id) {
            backToBackMediaList.push(allChildrens[i]);
        }
    }

    var gridData = getCheckListGridItemStructure(backToBackMediaList, 'Back to back media');
    return gridData;

}

//to get if same brand are scheduled back to back
export const getBackToBackBrandsInSchedule = async (data) => {
    var allChildrens = [];
    var backToBackBrandMediaList = [];

    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children];
        })
    }

    //allChildrens orderBy SlotDateTime
    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

    for (let i = 0; i < allChildrens.length - 1; i++) {
        if (allChildrens[i].Type > SCHEDULETYPE.Segment && allChildrens[i].mediaEpisode != undefined && allChildrens[i + 1].mediaEpisode != undefined) {
            if (allChildrens[i].mediaEpisode.Brand != undefined && allChildrens[i + 1].mediaEpisode.Brand != undefined && allChildrens[i].mediaEpisode.Brand._id == allChildrens[i + 1].mediaEpisode.Brand._id) {
                backToBackBrandMediaList.push(allChildrens[i]);
            }
        }
    }

    var gridData = getCheckListGridItemStructure(backToBackBrandMediaList, 'Media with back to back same brand');
    return gridData;

}

//to get if same product are scheduled back to back
export const getBackToBackProductsInSchedule = async (data) => {
    var allChildrens = [];
    var backToBackProductMediaList = [];

    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children];
        })
    }

    //allChildrens orderBy SlotDateTime
    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

    for (let i = 0; i < allChildrens.length - 1; i++) {
        if (allChildrens[i].Type > SCHEDULETYPE.Segment && allChildrens[i].mediaEpisode != undefined && allChildrens[i + 1].mediaEpisode != undefined) {
            if (allChildrens[i].mediaEpisode.Product != undefined && allChildrens[i + 1].mediaEpisode.Product != undefined && allChildrens[i].mediaEpisode.Product._id == allChildrens[i + 1].mediaEpisode.Product._id) {
                backToBackProductMediaList.push(allChildrens[i]);
            }
        }
    }

    var gridData = getCheckListGridItemStructure(backToBackProductMediaList, 'Media with back to back same product');
    return gridData;

}

//to get the headers with underrun and overrun
export const getUnderRunAndOverRunInSchedule = async (data) => {

    var mediaListWithUnderRunOverRun = [];

    for (let i = 0; i < data.length; i++) {

        var underRunOverRunData = data[i].children.filter((item) => item.Type == SCHEDULETYPE.UnderRun || item.Type == SCHEDULETYPE.OverRun);

        if (underRunOverRunData.length != 0) {
            mediaListWithUnderRunOverRun.push(data[i]);
        }
    }

    var gridData = getCheckListGridItemStructure(mediaListWithUnderRunOverRun, 'OverRun / UnderRun ');
    return gridData;

}

//DONE
export const hasNoSegments = async (data) => {

    var hasNoSegments = [];

    for (let i = 0; i < data.length; i++) {

        var segments = data[i].children.filter((item) => item.Type == SCHEDULETYPE.Segment);

        if (segments.length == 0) {
            hasNoSegments.push(data[i]);
        }
    }

    var gridData = getCheckListGridItemStructure(hasNoSegments, 'Not Segmented');
    return gridData;

}

//DONE
export const segmentSequence = async (data) => {

    var hasWrongSequence = [];

    for (let i = 0; i < data.length; i++) {

        var segments = data[i].children.filter((item) => item.Type == SCHEDULETYPE.Segment);

        if (segments.length > 0) {
            segments.map((x, i) => {
                if (x.BreakNo != i + 1) {
                    hasWrongSequence.push(x)
                }
            })
        }
    }

    var gridData = getCheckListGridItemStructure(hasWrongSequence, 'Wrong Sequence');
    return gridData;

}

//DONE
export const getBackToBackCommercialInSchedule = async (data) => {
    var allChildrens = [];
    var backToBackCommercialList = [];

    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children];
        })
    }

    //allChildrens orderBy SlotDateTime
    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

    for (let i = 0; i < allChildrens.length - 1; i++) {
        if (allChildrens[i].Type > SCHEDULETYPE.Segment && allChildrens[i].mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial && allChildrens[i].MediaEpisode_id == allChildrens[i + 1].MediaEpisode_id) {
            backToBackCommercialList.push(allChildrens[i]);
        }
    }

    var gridData = getCheckListGridItemStructure(backToBackCommercialList, 'Back to back Commercial');
    return gridData;

}

//DONE
export const getSameBreakSameCommercial = async (data) => {

    var sameBreakSameCommercial = [];

    if (data.length != 0) {
        data.map((parent) => {
            var childrens = parent.children;

            //allChildrens orderBy SlotDateTime
            childrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

            //FIND DISTINCT BreakNo.
            const distinctBreakNo = [...new Set(childrens.map(obj => obj.BreakNo))];

            for (let i = 0; i < distinctBreakNo.length - 1; i++) {
                const commercialWithSameBreakNo = childrens.filter((x) =>
                    x.BreakNo == distinctBreakNo[i] &&
                    x.Type != SCHEDULETYPE.OverRun && x.Type != SCHEDULETYPE.UnderRun &&
                    x.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial
                );

                const uniqMediaEpisodeId = [...new Set(commercialWithSameBreakNo.map(obj => obj.MediaEpisode_id))];
                uniqMediaEpisodeId.map((id) => {
                    var data = commercialWithSameBreakNo.filter((x) => x.MediaEpisode_id == id);
                    if (data.length > 1) sameBreakSameCommercial.push(...data)
                })
            }
        })
    }

    var gridData = getCheckListGridItemStructure(sameBreakSameCommercial, 'Same Break Same Commercial');
    return gridData;

}

//DONE
export const getSameBreakSameBrand = async (data) => {

    var sameBreakSameBrand = [];

    if (data.length != 0) {
        data.map((parent) => {
            var childrens = parent.children;

            //allChildrens orderBy SlotDateTime
            childrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

            //FIND DISTINCT BreakNo.
            const distinctBreakNo = [...new Set(childrens.map(obj => obj.BreakNo))];

            for (let i = 0; i < distinctBreakNo.length - 1; i++) {
                const mediaWithSameBreakNo = childrens.filter((x) => x.BreakNo == distinctBreakNo[i] && x.mediaEpisode.Brand != undefined);
                const uniqBrandId = [...new Set(mediaWithSameBreakNo.map(obj => obj.mediaEpisode.Brand?._id))];

                uniqBrandId.map((id) => {
                    var data = mediaWithSameBreakNo.filter((x) => x.mediaEpisode.Brand?._id == id);
                    if (data.length > 1) sameBreakSameBrand.push(...data)
                })
            }
        })
    }

    var gridData = getCheckListGridItemStructure(sameBreakSameBrand, 'Same Break Same Brand');
    return gridData;

}


//DONE
export const checkPreviewed = async (data) => {

    var nonPreviewedData = [];
    var noSegmentAttachmentData = [];
    var nonTXReadyData = [];

    for (let i = 0; i < data.length; i++) {

        const header = data[i]

        if (header.Segment_id == undefined || header.Segment_id.length == 0) continue;

        const mediaEpisodeSegment = await getData(ENTITYNAME.MediaEpisodeSegment, { query: ['_id', '=', header?.Segment_id] });

        var segmentDetails = mediaEpisodeSegment.data[0].SegmentDetails;

        //PREVIEWED
        var nonPreviewed = segmentDetails.filter((x) => x.IsPreviewed == undefined || !x.IsPreviewed);
        if (nonPreviewed.length > 0) {
            nonPreviewedData = [...nonPreviewedData, header];
        }

        //TXREADY
        if (mediaEpisodeSegment.data[0].TXReady == undefined || !mediaEpisodeSegment.data[0].TXReady) {
            nonTXReadyData = [...nonTXReadyData, header];
        }

        //TXREADY
        if (mediaEpisodeSegment.data[0].Subtitle == undefined || !mediaEpisodeSegment.data[0].Subtitle) {
            noSegmentAttachmentData = [...noSegmentAttachmentData, header];
        }

    }


    var gridData = {
        Previewed: getCheckListGridItemStructure(nonPreviewedData, 'Have non Previewed Segment'),
        TXReady: getCheckListGridItemStructure(nonTXReadyData, 'Not Tx Ready'),
        Subtitle: getCheckListGridItemStructure(noSegmentAttachmentData, 'No segment attachment')
    };

    // if(nonPreviewedData.length > 0){
    //     gridData.Previewed = nonPreviewedData //getCheckListGridItemStructure(nonPreviewedData,'Not Previewed')];
    // }
    // if(nonTXReadyData.length > 0){
    //     gridData.TXReady = nonTXReadyData //getCheckListGridItemStructure(nonTXReadyData,'Not Tx Ready');
    // }
    // if(noSegmentAttachmentData.length > 0){
    //     gridData.Subtitle = noSegmentAttachmentData //getCheckListGridItemStructure(noSegmentAttachmentData,'No segment attachment');
    // }

    return gridData;

}

//DONE
export const checkSegmentTXReady = async (data) => {

    var nonTXReadyData = [];

    for (let i = 0; i < data.length; i++) {

        const header = data[i]

        if (header.Segment_id == undefined || header.Segment_id.length == 0) continue;

        const mediaEpisodeSegment = await getData(ENTITYNAME.MediaEpisodeSegment, { query: ['_id', '=', header?.Segment_id] });

        //TXREADY
        if (mediaEpisodeSegment.data[0].TXReady == undefined || !mediaEpisodeSegment.data[0].TXReady) {
            nonTXReadyData = [...nonTXReadyData, header];
        }

    }


    var gridData = getCheckListGridItemStructure(nonTXReadyData, 'Not Tx Ready')
    return gridData;

}

//DONE
export const missingSegmentAttachment = async (data) => {

    var noSegmentAttachmentData = [];


    for (let i = 0; i < data.length; i++) {

        const header = data[i]

        if (header.Segment_id == undefined || header.Segment_id.length == 0) continue;

        const mediaEpisodeSegment = await getData(ENTITYNAME.MediaEpisodeSegment, { query: ['_id', '=', header?.Segment_id] });

        if (mediaEpisodeSegment.data[0].Subtitle) {
            var keys = Object.keys(mediaEpisodeSegment.data[0].SubtitleType);
            if (keys.length == 0 || mediaEpisodeSegment.data[0].File.length == 0) {
                noSegmentAttachmentData = [...noSegmentAttachmentData, header];
            }
        } else {
            noSegmentAttachmentData = [...noSegmentAttachmentData, header];
        }

    }


    var gridData = getCheckListGridItemStructure(noSegmentAttachmentData, 'No segment attachment')
    return gridData;

}

//DONE
export const compareSegments = async (data) => {

    var compareSegments = [];

    for (let i = 0; i < data.length; i++) {

        const header = data[i];

        var childrenSegments = header.children.filter((x) => x.Type == SCHEDULETYPE.Segment);

        if (header.Segment_id == undefined || header.Segment_id.length == 0) continue;

        const mediaEpisodeSegment = await getData(ENTITYNAME.MediaEpisodeSegment, { query: ['_id', '=', header.Segment_id] });

        var segmentDetails = mediaEpisodeSegment.data[0].SegmentDetails;

        if (segmentDetails.length != childrenSegments.length) {
            compareSegments.push(header);
        }


    }


    var gridData = getCheckListGridItemStructure(compareSegments, 'Segment Count Mismatch')
    return gridData;

}

//DONE
export const checkTimeRange = async (data) => {
    var allChildrens = [];
    var inValidTimeRangeBookedData = [];

    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children];
        })
    }

    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);
    var bookedData = allChildrens.filter((x) => x.Booking_id && x.Booking_id.length > 0);

    for (let i = 0; i < bookedData.length; i++) {
        const bookingItem = bookedData[i];
        //check time range
        // var [slotData,slotTime] = utility.convertMilisecondsToDateTimeString(bookingItem.SlotDateTime).split(' ');
        // var slotTimeInMilli = utility.convertStringWithFramesToMilliseconds(slotTime);

        const validBookingStartTime = bookingItem.booking.BookingDate + bookingItem.booking.TimeRangeFrom;
        const validBookingEndTime = bookingItem.booking.BookingDate + bookingItem.booking.TimeRangeTo;
        var baseStartTime = bookingItem.SlotDateTime;

        //CASE FOR NEXT DAY BOOKING  23:00 - 04:00
        if (bookingItem.booking.TimeRangeFrom > bookingItem.booking.TimeRangeTo) {
            var date = new Date(validBookingEndTime);
            const updatedValidBookingEndTime = date.setDate(date.getDate() + 1);

            if (baseStartTime < validBookingStartTime || baseStartTime > updatedValidBookingEndTime) {

                inValidTimeRangeBookedData.push(bookingItem);
            }

        }

        else if (baseStartTime < validBookingStartTime || baseStartTime > validBookingEndTime) {
            inValidTimeRangeBookedData.push(bookingItem);
        }

    }

    var gridData = getCheckListGridItemStructure(inValidTimeRangeBookedData, 'wrong Time Range')
    return gridData;

}

//DONE
export const clashCheck = async () => {

    var localStorageScheduleFilterData = utility.getSeassionValue(
        LOCALSTORAGE_KEY.publishData
    );

    var millisecondInOneDay = 86400000;

    var fromDate = localStorageScheduleFilterData.SlotDateTime - 7200000;
    var toDate = localStorageScheduleFilterData.SlotDateTime + millisecondInOneDay + 7200000

    var payload = {
        "Channel": localStorageScheduleFilterData.Channel.SID,
        "FromDate": fromDate,
        "ToDate": toDate
    }

    var clashCheckRes = await API.getProgramScheduleClash(payload);
    console.log(clashCheckRes);

    var finalData = clashCheckRes.data.map((x) => {
        return {
            MovieName: x.MovieName,
            SourceSlotDateTime: utility.convertMilisecondsToDateTimeString(x.SourceSlotDateTime),
            ClashedSlotDateTime: utility.convertMilisecondsToDateTimeString(x.ClashedSlotDateTime),
            SourceChannel: x.Channel.FullChannelName,
            ClashedChannel: x.clashChannel.FullChannelName
        }
    })

    return finalData;

}

//DONE
export const checkSalesBreakLimit = async (data) => {

    var inValidTimeRangeCommercialData = [];

    var timerange = await API.getDataLookup(ENTITYNAME.TimeRange, { sort: { SID: 1 } });

    for (let i = 0; i < timerange.data.length; i++) {

        const timeRange = timerange.data[i];

        var timeIn = utility.convertShortTimeStringToMilliSeconds(timeRange.TimeIn);
        var timeOut = utility.convertShortTimeStringToMilliSeconds(timeRange.TimeOut);
        var commercialLimit = timeRange.CommercialLimit

        data.map((x) => {
            var [slotData, slotTime] = utility.convertMilisecondsToDateTimeString(x.SlotDateTime).split(' ');
            var slotTimeInMilli = utility.convertStringWithFramesToMilliseconds(slotTime);

            if (slotTimeInMilli > timeIn && slotTimeInMilli < timeOut) {
                var commercialInTimeRange = x.children.filter((y) => y.mediaEpisode.MediaCategory != undefined && y?.mediaEpisode?.MediaCategory?.SID == MEDIACATEGORIES.Commercial)
                var commercialDuration = commercialInTimeRange.reduce((acc, value) => {
                    return acc + value.mediaEpisode.Duration
                }, 0);
                // console.log(commercialInTimeRange)
                // console.log(commercialDuration);
                if (commercialDuration > commercialLimit) {
                    inValidTimeRangeCommercialData.push(x);
                }
            }
        })

    }

    var gridData = getCheckListGridItemStructure(inValidTimeRangeCommercialData, 'Exceeding Commercial Limit')
    return gridData;

}

//DONE
export const checkSecondaryEventScheduled = async (data) => {

    // console.log(data);
    var noProtoType = [];

    if (data.length != 0) {
        data.map((parent) => {
            var children = parent.children;
            if (parent.Prototypes.length == 0) {
                var hasPrototypes = children.filter((x) => x?.Prototypes?.length > 0);
                if (hasPrototypes.length == 0) {
                    noProtoType.push(parent)
                }
            }
        })
    }

    var gridData = getCheckListGridItemStructure(noProtoType, 'No Secondary Events')
    return gridData;

}

//DONE
export const checkSecondaryEventDuplication = async (data) => {

    var protoTypeDuplication = [];

    if (data.length != 0) {
        data.map((parent) => {
            var children = parent.children;

            for (let i = 0; i < children.length; i++) {
                const program = children[i];
                if (program?.Prototypes?.length > 0) {
                    //CHECK DUPLICATE
                    var temp_id = [];
                    var tempStartOffset = []
                    program.Prototypes.map((x) => {
                        if (temp_id.includes(x._id) && tempStartOffset.includes(x.StartOffset)) {
                            protoTypeDuplication.push(program)
                        } else {
                            temp_id.push(x._id);
                            tempStartOffset.push(x.StartOffset)
                        }
                    })
                    // console.log(temp);
                }
            }
        })
    }

    var gridData = getCheckListGridItemStructure(protoTypeDuplication, 'Duplicate Secondary Events')
    return gridData;

}

//DONE
export const checkValidDate = async (data) => {

    var inValidData = [];

    for (let i = 0; i < data.length; i++) {

        if (!data[i].IsValid) {
            inValidData.push(data[i])
        }

    }

    var gridData = getCheckListGridItemStructure(inValidData, 'Invalid Dates');
    return gridData;


}

//to get the breaks with underrun
export const getBreakUnderRun = async (data) => {

    var breakWithUnderRun = [];

    for (let i = 0; i < data.length; i++) {

        var breakItemData = data[i].children.filter((item) => item?.IsBreak == true);
        for (let j = 0; j < breakItemData.length; j++) {
            const breakItem = breakItemData[j];
            var breakItemChildrens = breakItem.children.filter((obj) => obj.Type == SCHEDULETYPE.UnderRun);
            if (breakItemChildrens.length > 0) {
                breakWithUnderRun.push(breakItem);
            }

        }
    }

    var gridData = getCheckListGridItemStructure(breakWithUnderRun, 'Break UnderRun ');
    return gridData;

}

//to get the breaks with overrun
export const getBreakOverRun = async (data) => {

    var breakWithOverRun = [];

    for (let i = 0; i < data.length; i++) {

        var breakItemData = data[i].children.filter((item) => item?.IsBreak == true);
        for (let j = 0; j < breakItemData.length; j++) {
            const breakItem = breakItemData[j];
            var breakItemChildrens = breakItem.children.filter((obj) => obj.Type == SCHEDULETYPE.OverRun);
            if (breakItemChildrens.length > 0) {
                breakWithOverRun.push(breakItem);
            }

        }
    }
    var gridData = getCheckListGridItemStructure(breakWithOverRun, 'Break UnderRun ');
    return gridData;

}

export const checkClientSpotSeperation = async (data) => {
    var allChildrens = [];
    var invalidData = [];


    if (data.length != 0) {
        data.map((parent) => {
            allChildrens = [...allChildrens, ...parent.children.filter((x) => x.Booking_id?.length > 0)];
        })
    }

    //allChildrens orderBy SlotDateTime
    allChildrens.sort((a, b) => (a.SlotDateTime > b.SlotDateTime) ? 1 : -1);

    for (let i = 0; i < allChildrens.length; i++) {

        const bookedSpot = allChildrens[i];

        if (bookedSpot.Client && bookedSpot.Client.SpotSeparation > 0) {

            var timeRangeFrom = bookedSpot.SlotDateTime - bookedSpot.Client?.SpotSeparation;
            var timeRangeTo = bookedSpot.SlotDateTime + bookedSpot.Client?.SpotSeparation;

            var filterData = allChildrens.filter((x) => x.Booking_id != bookedSpot.Booking_id && x.Client?.SID == bookedSpot.Client.SID && x.SlotDateTime >= timeRangeFrom && x.SlotDateTime <= timeRangeTo);

            if (filterData.length > 0) {
                invalidData.push(bookedSpot);
            }

        }
    }

    var gridData = getCheckListGridItemStructure(invalidData, 'Client Spot Seperation');
    console.log(gridData);
    return gridData;

}

export const getInvalidSlotPlanning = async (data) => {
    let invalidData = data.filter((x) => x.InvalidReason != "");
    let gridData = []
    if (invalidData.length > 0) {
        gridData = invalidData.map((obj) => {
            return {
                Title: obj.mediaEpisode.Title,
                SlotDateTime: utility.convertMilisecondsToDateTimeString(obj.SlotDateTime),
                AssetId: obj.mediaEpisode.AssetId,
                Type: Object.keys(SCHEDULETYPE).find(key => SCHEDULETYPE[key] === obj.Type) ?? obj.mediaEpisode.MediaCategory.Description,
                Message: obj.InvalidReason,
            }
        })
    }
    return gridData;
}

export const getEpisodeSequencePlanning = async (data) => {
    let seasonData = data.filter((x) => x.mediaEpisode.Season_id != undefined);
    if (seasonData.length == 0) {
        return [];
    }

    let finalData = [];

    let groupBySeasonId = _.groupBy(seasonData, (item) => item.mediaEpisode.Season_id);

    for (const [key, value] of Object.entries(groupBySeasonId)) {
        for (let i = 0; i < value.length - 1; i++) {
            if (value[i].mediaEpisode.EpisodeNumber > value[i + 1].mediaEpisode.EpisodeNumber) {
                if (value[i + 1].mediaEpisode.EpisodeNumber == 1) {
                    continue;
                }
                finalData.push(value[i + 1]);
            }
        }
    }
    let gridData = getCheckListGridItemStructure(finalData, 'Invalid Episode Sequence');
    return gridData;
};

export const getSegmentAttachedPlanning = async (data) => {
    let segmentAttachedData = data?.filter((x) => x?.segments?.length == 0 || x?.segments == undefined);
    let gridData = getCheckListGridItemStructure(segmentAttachedData, 'Segment not attached');
    return gridData;
}

export const checkValidMedia = async (data) => {
    let gridData = [];
    let invalidArchiveData = [];
    let invalidPublishData = [];

    for (const element of data) {
        element.children.forEach((item) => {
            if (item?.mediaEpisode?.Archive) {
                invalidArchiveData.push(item);
            }
            else {
                if (item?.Type == SCHEDULETYPE.ShortForm && item?.mediaEpisode?.Publishings?.length > 0) {

                    let publishData = item.mediaEpisode.Publishings;

                    let inValidMedia = publishData.some((x) => {
                        
                        let isTBA = x.TBA ?? false;
                        let scheduleDateDay = new Date(item.ScheduleDate).getUTCDay()
                        let isDayRight = x.Days.includes(scheduleDateDay.toString());
                        let isChannelMatch = x.Channel.some(y => y.SID == item.Channel.SID);

                        return !(x.PublishStartDate <= item.ScheduleDate && (isTBA || x.PublishEndDate >=  item.ScheduleDate) && isDayRight && isChannelMatch)

                    })

                    if (inValidMedia) {
                        invalidPublishData.push(item);
                    }

                }
            }
        });
    }
    gridData = getCheckListGridItemStructure(invalidArchiveData, 'Archived');
    gridData = [...gridData, ...getCheckListGridItemStructure(invalidPublishData, 'Invalid Publishing Rights')];
    return gridData;
}

export const checkBackToBackMediaCategoryType = async (data) => {
    let gridData = [];
    let backToBackMediaCategoryType = [];
    let storePrevMediaCategoryType = '';
    for (const element of data) {
        let finalData = element.children.filter((x) => x?.Type == SCHEDULETYPE.ShortForm);
        finalData.forEach((item) => {
            if (storePrevMediaCategoryType == item?.mediaEpisode?.MediaCategoryType?.SID) {
                backToBackMediaCategoryType.push(item);
            }
            storePrevMediaCategoryType = item?.mediaEpisode?.MediaCategoryType?.SID;
        });
    }
    gridData = getCheckListGridItemStructure(backToBackMediaCategoryType, 'Back to Back Media Category Type');
    return gridData;
}

export const validateTimeCodeAndDuration = async (data) => {
    let gridData = [];
    let invalidTimeCode = [];
    
    for (const element of data) {
        let finalData = element.children.filter(x => x.Type != SCHEDULETYPE.UnderRun && x.Type != SCHEDULETYPE.OverRun);
        finalData.forEach((item) => {

            const durationByTimeCode = item.TcOut - item.TcIn;
            if (durationByTimeCode != item.Duration) {
                invalidTimeCode.push(item);
            }
        });
    }
    gridData = getCheckListGridItemStructure(invalidTimeCode, 'Invalid Time Code or Duration');
    return gridData;
}


export const BackToBackSameEpisodeCheck = async (data) => {

    let headers = data.sort((a, b) => a.SlotDateTime - b.SlotDateTime);
    let backToBackSameEpisodeCheck = [];

    if (data.length !== 0) {
        // Check for back-to-back same episodes in the same index
        headers.map((header, index) => {
            if (index < headers.length - 1 && header.MediaEpisode_id === headers[index + 1].MediaEpisode_id) {
                backToBackSameEpisodeCheck.push(header);
            }
        });
    }    
    let gridData = getCheckListGridItemStructure(backToBackSameEpisodeCheck, 'Episode with back to back same Episode');
    return gridData;
}

export const validatePreviousDayEndTime = async (scheduleTimeInfo) => {

    //CHECK IF PREVIOUS DAY END TIME = TODAY'S START TIME
    let isValid = utility.convertStringWithFramesToMilliseconds(scheduleTimeInfo.PreviousScheduleEndDateTime) == utility.convertStringWithFramesToMilliseconds(scheduleTimeInfo.StartDateTime);

    return isValid;
    
}

export const CommercialLimitPerHour = async (data, offset = 0) => {

    let timeRangeRes = await API.getDataLookup(ENTITYNAME.TimeRange, { sort: { SID: 1 } });
    let headers = data.sort((a, b) => a.SlotDateTime - b.SlotDateTime);

    let date = moment(new Date(data[0].SlotDateTime)).format("YYYY-MM-DD");

    let commercialOverFlowHeader = [];

    if(timeRangeRes.success && timeRangeRes.data.length > 0){
        
        for (let i = 0; i < timeRangeRes.data.length; i++) {
            const timeRange = timeRangeRes.data[i];

            let fromTime = utility.convertStringDatetoMilliseconds(date) + utility.convertShortTimeStringToMilliSeconds(timeRange.TimeIn) + offset;
            let toTime = utility.convertStringDatetoMilliseconds(date) + utility.convertShortTimeStringToMilliSeconds(timeRange.TimeOut) + offset;
            
            let headersUnderTimeRange = headers.filter((x) => x.SlotDateTime >= fromTime && x.SlotDateTime < toTime);
            let commercialsUnderTimeRange = [];

            headersUnderTimeRange.map((x) => {

                let commercials = x.children.filter((y) => y.Type == SCHEDULETYPE.ShortForm && y.mediaEpisode.MediaCategory.SID == MEDIACATEGORIES.Commercial);
                commercialsUnderTimeRange = [...commercialsUnderTimeRange, ...commercials];

            });

            if(timeRange.CommercialLimit < commercialsUnderTimeRange.reduce((acc, value) => { return acc + value.Duration }, 0)){
                commercialOverFlowHeader.push(...headersUnderTimeRange);
            }
        }
    }

    let gridData = getCheckListGridItemStructure(commercialOverFlowHeader, 'Header with commercial limit overflow');
    return gridData;
}

