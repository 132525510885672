/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

const ResolutionEditForm = (props) => {

    const calculateAspectRatio = (width, height) => {
        const gcd = (a, b) => {
            return b == 0 ? a : gcd(b, a % b);
        };
        const gcdValue = (width > 0 && height > 0) ? gcd(width, height) : 0;
        const aspectRatioWidth = width / gcdValue;
        const aspectRatioHeight = height / gcdValue;
        return `${aspectRatioWidth} : ${aspectRatioHeight}`;
    };
    
    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Height: props.item.Height ?? 0,
        Width: props.item.Width ?? 0,
        AspectRatio: calculateAspectRatio(props.item.Width, props.item.Height) ?? '',

    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();

    const onChange = (e) => {
        if(e.target.name == "Height" || e.target.name == "Width"){
            const newWidth = parseInt(e.target.name === "Width" ? e.target.value : dataItem.Width);
            const newHeight = parseInt(e.target.name === "Height" ? e.target.value : dataItem.Height);
            const aspectRatio = calculateAspectRatio(parseInt(newWidth), parseInt(newHeight));
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, AspectRatio: aspectRatio});
        } else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {
        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(lang.please_enter_description_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (!dataItem.Width || dataItem.width < 0) {
            toast.error(lang.width_should_be_greater_than_zero_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (!dataItem.Height || dataItem.Height < 0) {
            toast.error(lang.height_should_be_greater_than_zero_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!isValid()) return;
        let localData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Description: dataItem.Description,
            Height: dataItem.Height,
            Width: dataItem.Width,
            AspectRatio: dataItem.AspectRatio ?? "",
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description]],
        };
        console.log(localData)
        let res = await API.saveData(ENTITYNAME.Resolution, localData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.RESOLUTION, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.Resolution);
            props.cancelEdit();
            props.refresh();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <BossDialog
                title={props.item.Description ?? lang.create_resolution_dialog_header}
                onClose={props.cancelEdit}
                width={"40%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row">
                            <div className="col-12">
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label htmlFor="TabView">{lang.description_label} *</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="Description"
                                            onChange={onChange}
                                            value={dataItem.Description}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.width_label} *</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="number"
                                            name="Width"
                                            onChange={onChange}
                                            value={dataItem.Width}
                                            min={0}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.height_label} *</label>
                                        <input
                                            className="form-control form-control-sm"
                                            type="number"
                                            name="Height"
                                            onChange={onChange}
                                            value={dataItem.Height}
                                            min={0}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label htmlFor="TabView">{lang.aspect_ratio_label}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="AspectRatio"
                                            onChange={onChange}
                                            value={dataItem.AspectRatio}
                                            disabled
                                        />
                                    </div>
                                    <div className="col mt-4">
                                        <input
                                            type="checkbox"
                                            name="Archive"
                                            value={dataItem.Archive}
                                            checked={dataItem.Archive}
                                            onChange={onChange}
                                        />
                                        <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    );
};

export default ResolutionEditForm;