import React, { useEffect, useRef, useState } from 'react';
import { getMyPlansWithAllPlans } from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { DIGITALSIGN_TRANSACTION_STATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import { getData } from '../../framework/API/api';
import moment from 'moment';
import * as API from '../../framework/API/api_digitalSign'
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';
import { DigitalSignChoosePlanForm } from '../DigitalSign/plan/DigitalSignChoosePlanForm';
import { Loader } from '../../framework/forms/helpercomponents/Loader';

const MyPlansPopup = ({ onClose }) => {

    const [loading, setLoading] = useState(false);
    const [myPlans, setMyPlans] = useState([]);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [showPurchasePlan, setShowPurchasePlan] = useState(false);
    const [userData, setUserData] = useState({});

    const lang = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        setUserData(userData);
        let res = await getMyPlansWithAllPlans();
        if (!res.success) {
            toast.error(res.message);
        } else {
            setMyPlans(res.data?.myPlans ?? []);
        }
        setLoading(false);
    }

    const showTransactionHistoryPopup = async () => {
        setShowTransactionHistory(true);
        let res = await getData(ENTITYNAME.DigitalSignTransactionHistory, { query: ["userID", "=", userData._id] });
        if (res.success) {
            setTransactionHistoryData(res.data);
        } else {
            setShowTransactionHistory(false);
            toast.error(res.message);
        }
    }

    const downloadInvoice = async (trHistory) => {
        let res = await API.paymentInvoice(trHistory)
        if (res.success) {
            window.open(FILEURL.BASEURL + "downloadReport/" + res.data);
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <BossDialog
            onClose={onClose}
            showHeader={true}
            style={{ background: "transparent" }}
            width={showTransactionHistory ? "800px" : showPurchasePlan ? "800px" : "600px"}

        >
            {
                loading ?
                    <Loader height="53vh"/>
                    : showPurchasePlan ?
                        <DigitalSignChoosePlanForm userData={userData} onClose={()=> {setShowPurchasePlan(false); loadData();}}/>
                        : showTransactionHistory ?
                            <div>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>Transaction History</h1>
                                <div className='mt-4' style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table border solid 2px">
                                        <thead className='border solid 2px'>
                                            <tr>
                                                <th style={{ width: "30px" }}></th>
                                                <th scope="col" style={{ width: "105px" }}>Date</th>
                                                <th scope="col">Order ID</th>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Screens</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Tenure(Days)</th>
                                                <th scope="col">Transaction Status</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border solid 2px'>
                                            {transactionHistoryData.map(trHistory => <tr key={trHistory._id}>
                                                <td>{trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Confirmed && <i className='fa fa-download cursor-pointer' onClick={() => downloadInvoice(trHistory)}></i>}</td>
                                                <td style={{ width: "30px" }}>{moment(trHistory.orderCreatedOn).format("ll")}</td>
                                                <td>{trHistory.orderID.replace("order_", "")}</td>
                                                <td>{trHistory.plan.Name}</td>
                                                <td>{trHistory.plan.screens}</td>
                                                <td>{trHistory.plan.storage}</td>
                                                <td>{trHistory.plan.Tenure}</td>
                                                <td style={{ textAlign: "center", color: "white" }}><div style={{ backgroundColor: trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Cancelled ? "#f4511e" : trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Pending ? "#deb633" : "#03a782", display: "inline-block", padding: "2px 8px", borderRadius: "5px", borderColor: "white", fontSize: "12px" }}>{Object.keys(DIGITALSIGN_TRANSACTION_STATUS)[Object.values(DIGITALSIGN_TRANSACTION_STATUS).indexOf(trHistory.status)]}</div></td>
                                                <td>{trHistory.currency + " " + trHistory.amount}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{float: 'right', marginTop: '10px'}}>
                                    <SaveButton tooltip="OK" title="OK" onClick={() => { setShowTransactionHistory(false) }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>
                            : <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>My Plan</h1>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <span style={{ fontWeight: "bold", paddingTop: "5px", fontSize: "18px", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.name ?? "")}</span>
                                        <span style={{ fontWeight: "bold", paddingTop: 0, marginTop: 0, fontSize: "14px", color: "grey", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.Company?.Name ?? "")}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }} className='text-success'> {parseInt(myPlans.reduce((acc, plan) => acc + plan.plan.screens, 0)) - parseInt(myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0))}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Active  Screens</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }}> {myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0)}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Balance  Screens</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4' style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Total Screens</th>
                                                <th scope="col">Screens Left</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Tenure</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {myPlans.map(plan => <tr key={plan._id}>
                                                <td>{plan.plan.Name}</td>
                                                <td>{plan.plan.screens}</td>
                                                <td>{plan.balanceScreens}</td>
                                                <td>{plan.plan.storage}MB</td>
                                                <td className={`${plan.balanceScreens > 0 ? "text-success" : "text-danger"}`}>{plan.balanceScreens > 0 ? "Active" : "Inactive"}</td>
                                                <td>{userData?.Company?.SignUpData?.currency ?? 'INR' + " " + plan?.plan?.Price}</td>
                                                <td>{plan.plan.Tenure}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                    <CancelButton onClick={onClose} style={{ borderColor: "white", marginLeft: 0, }} />
                                    <SaveButton tooltip="Transaction History" title="Transaction History" onClick={showTransactionHistoryPopup} style={{ borderRadius: "5px", backgroundColor: "#03a782", borderColor: "white" }} />
                                    <SaveButton tooltip="Purchase Plan" title="Purchase Plan" onClick={() => {
                                        setShowPurchasePlan(true);
                                    }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>}
        </BossDialog>
    );
}


export default MyPlansPopup;
