import { toast } from "react-toastify";
import { DIGITAL_SIGN_SOCKET_BASEURL } from "../constant/baseurl";
import { COLUMNSTYPE, CONFIGURL, DIGITALSIGNURL, ENTITYNAME, GetHeader, LOCALSTORAGE_KEY, LOGINURL, RAZORPAYURL } from "../constant/constant";
import { utility } from "../utility/utilityProvider";

let defaultArgs = {
  query: [],
  sort: { SID: 1 },
  page: null,
  perPage: null,
  archive: false
}

export const sendEvent = async (socketID, data) => {
  try {
    let finalData = {
      socketID: socketID,
      data: data
    }
    let FINALURL = DIGITAL_SIGN_SOCKET_BASEURL + '/api/sendEvent';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    try {
      let responseData = await response.json();
      return responseData;
    } catch (jsonError) {
      return { success: false, message: "Unable to reach event server." };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
}

export const refreshPlaylist = async (socketID) => {
  try {
    let finalData = {
      socketID: socketID,
    }
    let FINALURL = DIGITAL_SIGN_SOCKET_BASEURL + '/api/refreshPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    try {
      let responseData = await response.json();
      return responseData;
    } catch (jsonError) {
      return { success: false, message: "Unable to reach event server." };
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export const joinCompany = async (socketID, companyId, screenGroupId) => {
  try {
    let finalData = {
      socketID: socketID,
      companyId: companyId,
      screenGroupId: screenGroupId
    }
    let FINALURL = DIGITAL_SIGN_SOCKET_BASEURL + '/api/joinCompany';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    try {
      let responseData = await response.json();
      return responseData;
    } catch (jsonError) {
      return { success: false, message: "Unable to reach event server." };
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export const saveData = async (entityname, saveData) => {

  saveData = utility.prepareSaveData(entityname, saveData)

  try {
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    let FINALURL = DIGITALSIGNURL.BASEURL + "saveData/" + entityname;

    //prepare data
    const finalData = {
      data: saveData,
      user: { _id: user?._id ?? "", name: user?.name ?? "" }
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };

    const response = await fetch(FINALURL, Finalinit);
    const responseData = await response.json();

    utility.checkTokenExpire(responseData);
    return responseData;

  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getEntitiesWithSearch = async (entityname, args = defaultArgs, columns, searchText, filters, Company_id) => {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters,
      Company_id: Company_id
    };

    if (entityname == ENTITYNAME.Users) {
      const user = utility.getValue(LOCALSTORAGE_KEY.userData);
      if (user && user.RoleType) {
        finalData.query = [...finalData.query, ["RoleType.ID", ">=", user.RoleType.ID]]
      }
    }

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntitiesWithSearch/' + entityname;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getDigitalSignDashboardData = async (companiesFilter, isEnterpriseLogin) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignDashboardData';
    let Finalinit = {
      ...GetHeader("POST"), "body": JSON.stringify({
        companies: companiesFilter,
        isEnterpriseLogin: isEnterpriseLogin
      })
    };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    utility.checkTokenExpire(responseData);
    return responseData;

  } catch (error) {
    toast.error(error.message);
  }
}

export const getDigitalSignScreen = async (args = defaultArgs, searchText) => {

  utility.checkSessionExpire();

  try {

    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      searchText: searchText || ''
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignScreen';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const activateScreen = async (otp, Name, companyPlanId, company_id) => {
  console.log(company_id);


  utility.checkSessionExpire();

  try {
    let user = utility.getValue(LOCALSTORAGE_KEY.userData);
    let FINALURL = DIGITALSIGNURL.BASEURL + 'activateScreen';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ otp: otp, Name: Name, companyPlanId: companyPlanId, user: user, company_id: company_id }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const canActivateScreen = async (company_id) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'canActivateScreen';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ company_id: company_id }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const removeScreenAndLog = async (plan_id, screen_id, user) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'removeScreenAndLog';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ plan_id: plan_id, screen_id: screen_id, user: user }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getPlaylists = async (args = defaultArgs, searchText) => {

  utility.checkSessionExpire();

  let finalData = {
    query: args.query || defaultArgs.query,
    sort: args.sort || defaultArgs.sort,
    page: args.page || defaultArgs.page,
    perPage: args.perPage || defaultArgs.perPage,
    archive: args.archive || defaultArgs.archive,
    searchText: searchText || ''
  };

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPlaylists';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const createDuplicatePlaylist = async (playlistId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'createDuplicatePlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ id: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getPlaylist = async (playlistId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ playlistId: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const deletePlaylist = async (playlistId) => {
  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'deletePlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ id: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getPublishPlaylist = async (playlistId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getPublishPlaylist';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ playlistId: playlistId }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getOverlaysByOverlayIds = async (overlayIds) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getOverlaysByOverlayIds';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ overlayIds: overlayIds }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getData = async (entityname, args = defaultArgs) => {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
    };
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntitiesWithSearch/' + entityname;
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getDataLookup = async (entityname, args = defaultArgs, localStorageKey = '') => {

  utility.checkSessionExpire();

  try {
    let dataLookup = utility.getValue(entityname);
    if (dataLookup != null) return dataLookup;

    let finalData = {
      query: args.query,
      sort: args.sort,
      page: args.page,
      perPage: args.perPage
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntitiesWithSearch/' + entityname;

    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();
    if (data.success) {
      let key = localStorageKey == '' ? entityname : localStorageKey
      utility.setValue(key, data);
      return data;
    }
    utility.checkTokenExpire(data)
    return data;

  }
  catch (error) {
    toast.error(error.message);
  }

}

export const getFormData = async (entityname, userId) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'formData/' + entityname;
    const finalData = {
      data: userId ? userId : null
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    if (resData.success) {
      return resData.data.sort((a, b) => a.sequence - b.sequence).filter((x) => x.type != COLUMNSTYPE.hidden);
    }
    utility.checkTokenExpire(resData);
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const onDigitalSignup = async (data) => {

  try {
    let FINALURL = LOGINURL.BASEURL + 'onDigitalSignup';
    const finalData = {
      data: data
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getSignupMasters = async () => {

  try {
    let FINALURL = CONFIGURL.BASEURL + 'getSignupMasters';
    let Finalinit = GetHeader();
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const forgotPassword = async (email) => {

  try {
    let FINALURL = LOGINURL.BASEURL + 'forgotPassword';
    const finalData = {
      email: email
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    const response = await fetch(FINALURL, Finalinit);
    const resData = await response.json();
    return resData;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getScreenGroup = async (args = defaultArgs, searchText, Company_id) => {

  utility.checkSessionExpire();

  try {

    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      searchText: searchText || '',
      Company_id: Company_id
    };

    console.log(finalData);

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getScreenGroup';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getMyPlansWithAllPlans = async () => {

  utility.checkSessionExpire();
  try {
    let Finalinit = { ...GetHeader("POST"), };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getMyPlansWithAllPlans';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const razorpayCreateOrder = async (amount, plan) => {

  utility.checkSessionExpire();
  try {
    let currencyCode = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?.SignUpData?.currency ?? 'INR';
    const userID = utility.getValue(LOCALSTORAGE_KEY.user_id);
    let Finalinit = { ...GetHeader("POST"), body: JSON.stringify({ amount: amount, currencyCode: currencyCode, userID: userID, plan: plan }) };
    let FINALURL = RAZORPAYURL.BASEURL + 'createOrder';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const verifyPayment = async (razorpay_order_id, razorpay_payment_id, razorpay_signature) => {

  utility.checkSessionExpire();
  try {
    let Finalinit = { ...GetHeader("POST"), body: JSON.stringify({ razorpay_order_id: razorpay_order_id, razorpay_payment_id: razorpay_payment_id, razorpay_signature: razorpay_signature }) };
    let FINALURL = RAZORPAYURL.BASEURL + 'verifyPayment';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const updateTransactionHistory = async (orderID, status) => {

  utility.checkSessionExpire();
  try {
    let Finalinit = { ...GetHeader("POST"), body: JSON.stringify({ orderID: orderID, status: status }) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'updateTransactionHistory';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const canUploadContent = async (contentSize, company_id) => {
  // contentSize in MB
  utility.checkSessionExpire();
  try {
    let Finalinit = {
      ...GetHeader("POST"), body: JSON.stringify({
        contentSize: contentSize,
        company_id: company_id
      })
    };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'canUploadContent';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getLastPlayItem = async (args, serialNumber) => {

  utility.checkSessionExpire();
  try {
    let Finalinit = {
      ...GetHeader("POST"), body: JSON.stringify({
        page: args.page,
        perPage: args.perPage,
        serialNo: serialNumber
      })
    };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getLastPlayItem';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getDigitalSignAdminDashboardData = async () => {

  utility.checkSessionExpire();

  try {

    let companyData = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignAdminDashboardData';
    let Finalinit = {
      ...GetHeader("POST"), "body": JSON.stringify({
        companyId: companyData?._id
      })
    };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    utility.checkTokenExpire(responseData);
    return responseData;

  } catch (error) {
    toast.error(error.message);
  }
}

export async function deleteContent(payload) {

  try {
    let finalData = {
      data: payload
    }
    let FINALURL = DIGITALSIGNURL.BASEURL + 'deleteContent';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log("error", error);
    toast.error(error.message);
  }

}

export async function getEntityWithLookup(entityname, SID, lookup) {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: ['SID', '=', parseInt(SID)],
      lookup: lookup
    }
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getEntityWithLookup/' + entityname;
    let Finalinit = { ...GetHeader('POST', false), "body": JSON.stringify(finalData) }

    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const getContentGroup = async (args = defaultArgs, columns, searchText, filters, Company_id) => {

  utility.checkSessionExpire();

  try {
    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      archive: args.archive || defaultArgs.archive,
      dataColumns: columns || [],
      searchText: searchText || '',
      filters: filters,
      Company_id: Company_id
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getContentGroup';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export async function saveDigitalSignCompany(data) {

  try {
    let finalData = {
      data: data
    }
    let FINALURL = DIGITALSIGNURL.BASEURL + 'saveDigitalSignCompany';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }

}

export const getDigitalSignCompanyData = async (args = defaultArgs, columns, searchText) => {

  utility.checkSessionExpire();

  try {

    let finalData = {
      query: args.query || defaultArgs.query,
      sort: args.sort || defaultArgs.sort,
      page: args.page || defaultArgs.page,
      perPage: args.perPage || defaultArgs.perPage,
      dataColumns: columns || [],
      archive: args.archive || defaultArgs.archive,
      searchText: searchText || ''
    };

    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignCompanyData';
    let response = await fetch(FINALURL, Finalinit);
    let data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);

    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const paymentInvoice = async (trHistory) => {
  try {

    let finalData = {
      data: trHistory
    }
    console.log(finalData);


    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'paymentInvoice';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }
}

export const downloadDigitalSignAsrun = async (filterData) => {
  try {
    let finalData = {
      data: filterData
    }
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(finalData) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'downloadDigitalSignAsrun';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }

}

export const canPlay = async (playlistData) => {

  utility.checkSessionExpire();

  try {
    let FINALURL = DIGITALSIGNURL.BASEURL + 'canPlay';
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify({ data: playlistData }) };
    let response = await fetch(FINALURL, Finalinit);
    let responseData = await response.json();
    return responseData;
  } catch (error) {
    toast.error(error.message);
  }
}

export const getDigitalSignAsrunErrorReport = async (payload) => {
  try {
    let Finalinit = { ...GetHeader("POST"), "body": JSON.stringify(payload) };
    let FINALURL = DIGITALSIGNURL.BASEURL + 'getDigitalSignAsrunErrorReport';
    const response = await fetch(FINALURL, Finalinit);
    const data = await response.json();

    utility.checkTokenExpire(data);
    utility.checkIsLicenseExpire(data);
    return data;
  }
  catch (error) {
    toast.error(error.message);
  }

}