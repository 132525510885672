/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { FILEURL, EPGREPORTTYPE, ENTITYNAME, OPERATORS, LOCALSTORAGE_KEY, MODULETYPES, SCHEDULETYPE, REPORT_TYPE, MEDIACATEGORIES } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";
import { Description } from "@mui/icons-material";
import { RadioButton } from "@progress/kendo-react-inputs";


export const MediaContentReport = (props) => {

    const blankDataItem = {
        MediaCategory: { SID: 0, Description: "All" },
        MediaCategoryType: { SID: 0, Description: "All" },
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();
    const [isLongContent, setIsLongContent] = useState(true);
    const [mediaCategories, setMediaCategories] = useState([]);
    const [mediaCategoryTypes, setMediaCategoryTypes] = useState([]);
    const [filterMediaCategory, setFilterMediaCategory] = useState([]);
    const [filterMediaCategoryType, setFilterMediaCategoryType] = useState([]);
    const [footerMessage, setFooterMessage] = useState('');
    const navigate = useNavigate();



    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });
        setMediaCategories([...mediaCategoryRes.data.filter((x) => x.SID != MEDIACATEGORIES.Series && x.SID != MEDIACATEGORIES.Season)]);

        setFilterMediaCategory([{ SID: 0, Description: "All" }, ...mediaCategoryRes.data.filter((x) => x.isLong && x.SID != MEDIACATEGORIES.Series && x.SID != MEDIACATEGORIES.Season)]);
        var mediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { SID: 1 } });
        setMediaCategoryTypes([{ SID: 0, Description: "All" }, ...mediaCategoryTypeRes.data]);
    }

    const loadAndExportData = async () => {

        setFooterMessage(lang.please_wait_export_report_message);

        let payload = {
            mediaCategory: dataItem.MediaCategory.SID,
            mediaCategoryType: dataItem.MediaCategoryType.SID,
            isLong: isLongContent
        }

        console.log(payload)

        let res = await API.exportMediaContent(payload);

        console.log(res);

        if (res.success) {
            setTimeout(() => {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.data);
                toast.success(lang.content_report_is_exported_successfully_message, { position: toast.POSITION.TOP_RIGHT });
                setFooterMessage(lang.content_report_is_exported_successfully_message);
            }, 5000);
        } else {
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
            setFooterMessage('');
        }

    }

    const onChange = async (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        if(!isLongContent){
            console.log([{ SID: 0, Description: "All" }, ...mediaCategoryTypes.filter((x) => x.MediaCategorySID == e.target.value.SID)])
            setFilterMediaCategoryType([{ SID: 0, Description: "All" }, ...mediaCategoryTypes.filter((x) => x.MediaCategorySID == e.target.value.SID)])
        }
    };

    const onMediaTypeChange = (e) => {

        if (e.value == "LongForm") {
            setIsLongContent(true);
            setFilterMediaCategory(mediaCategories.filter((x) => x.isLong))
        } else {
            setIsLongContent(false);
            setFilterMediaCategory(mediaCategories.filter((x) => !x.isLong))
        }

        setDataItem({ ...blankDataItem });

    }

    return (<>
        {<BossDialog
            title={lang.media_content_report_page_breadcrumb}
            onClose={() => navigate('/home')}
            width={"650px"}
        >
            <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
                <div className="row">
                    <div className="col-8">
                        <div className="form-group">
                            <label htmlFor="">
                                <RadioButton
                                    name="LongForm"
                                    value="LongForm"
                                    checked={isLongContent}
                                    label={lang.long_form_label}
                                    onChange={onMediaTypeChange}
                                />
                                <RadioButton
                                    style={{ marginLeft: "25px" }}
                                    name="ShortForm"
                                    value="ShortForm"
                                    checked={!isLongContent}
                                    label={lang.short_form_label}
                                    onChange={onMediaTypeChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.media_category_label}</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={filterMediaCategory}
                                name="MediaCategory"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.MediaCategory}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
                {!isLongContent && <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.media_category_type_label}</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={filterMediaCategoryType}
                                name="MediaCategoryType"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.MediaCategoryType}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>}

                <div className="row" style={{ marginTop: "05px" }}>
                    <div className="col-12">
                        <ActionButton title={lang.export_button_tooltip} name={lang.export_button_text} onClick={loadAndExportData} style={{ margin: '0px', borderRadius: '5px' }} />
                    </div>
                </div>
            </div>
            <div className='text-right mt-3' style={{ position: 'fixed', height: '30px', width: '652px', marginLeft: '-20px', padding: '5px', background: "#3A3633" }}>
                <span style={{ color: 'white' }}>{footerMessage} </span>
            </div>
        </BossDialog>}
    </>)
}
export default MediaContentReport;